.card {
    border: none;
    padding: '20px 0';
}

.card-img-top {
    border-radius: 20px;
    transition: all 300ms;
}

.card-img-top:hover {
    box-shadow: 0 0 20px #222 !important;
    transform: scaleX(1.025) scaleY(1.025);
}

h3.h3 {
    margin-top: 20px;
}