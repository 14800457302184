body {
  background-color: #fff;
}

pre {
  background-color: gainsboro;
  padding: 20px;
  border-radius: 5px;

}

/* Style the copy button */
.copy-button {
  display: block;
  border: none;
  background-color: #3498db;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  right: 20px;
  top: -20px;
  position: relative;
}

.copy-button:active {
  background-color: #afd7f2;
  
}

.center {
  margin: 0 auto;
  width: 80%;
}

.no-underline {
  text-decoration: none;
}

.col-md-8.col-xs-12   img {
  max-width: 100%
}